import React from 'react';
import { fabric } from 'fabric';

import Button from './Button';

import __ from './../utils/translation';

import { ReactComponent as IconFlipH } from './../icons/flip-h.svg';
import { ReactComponent as IconFlipV } from './../icons/flip-v.svg';
import { ReactComponent as IconBringFwd } from './../icons/bring-forward.svg';
import { ReactComponent as IconBringBack } from './../icons/bring-back.svg';
import { ReactComponent as IconDuplicate } from './../icons/duplicate.svg';
import { ReactComponent as IconRemove } from './../icons/bin.svg';
import { ReactComponent as IconGroup } from './../icons/group.svg';
import { ReactComponent as IconUngroup } from './../icons/ungroup.svg';



const SelectionObjectSettings = ({ canvas, activeSelection }) => {

  const handleFlipH = () => {
    activeSelection.set('flipX', !activeSelection.flipX)
    canvas.renderAll()
    canvas.trigger('object:modified')
  }


  const handleFlipV = () => {
    activeSelection.set('flipY', !activeSelection.flipY)
    canvas.renderAll()
    canvas.trigger('object:modified')
  }


  const handleBringFwd = () => {
    canvas.bringForward(activeSelection)
    canvas.renderAll()
    canvas.trigger('object:modified')
  }


  const handleBringBack = () => {
    canvas.sendBackwards(activeSelection)
    canvas.renderAll()
    canvas.trigger('object:modified')
  }


  const handleDuplicate = () => {
    let clonedObjects = []
    let activeObjects = canvas.getActiveObjects()
    activeObjects.forEach(obj => {
      obj.clone(clone => {
        canvas.add(clone.set({
          strokeUniform: true,
          left: obj.aCoords.tl.x + 20,
          top: obj.aCoords.tl.y + 20
        }));

        if (activeObjects.length === 1){
          canvas.setActiveObject(clone)
        }
        clonedObjects.push(clone)
      })
    })

    if (clonedObjects.length > 1) {
      let sel = new fabric.ActiveSelection(clonedObjects, {
        canvas: canvas,
      });
      canvas.setActiveObject(sel)
    }

    canvas.requestRenderAll()
    canvas.trigger('object:modified')
  }


  const handleDel = () => {
    canvas.getActiveObjects().forEach(obj => {
      canvas.remove(obj)
    })
    canvas.discardActiveObject().requestRenderAll()
    canvas.trigger('object:modified')
  }


  const handleGroup = () => {
    if (activeSelection.type !== 'activeSelection') return

    var isFixed = false;

    canvas.getActiveObjects().forEach(obj => {
      if(obj.default)
        isFixed = true;
    })

    canvas.getActiveObject().toGroup()

    /*canvas.getActiveObject().lockMovementX = true;
    canvas.getActiveObject().lockMovementY = true;
    canvas.getActiveObject().lockScalingX = true;
    canvas.getActiveObject().lockScalingY = true;
    canvas.getActiveObject().lockRotation = true;
    canvas.getActiveObject().hasControls = false;*/

    canvas.requestRenderAll()
    canvas.trigger('object:modified')
  }

  const handleUngroup = () => {
    if (canvas.getActiveObject().type !== 'group') return

    canvas.getActiveObject().toActiveSelection()

    const ungroupedObjs = canvas.getActiveObjects()
    canvas.discardActiveObject()
    const sel = new fabric.ActiveSelection(ungroupedObjs, {
      canvas: canvas,
    });
    canvas.setActiveObject(sel);

    canvas.requestRenderAll()
    canvas.trigger('object:modified')
    canvas.trigger('test')
  }


  return (
    <div className="setting flex small-buttons flex-column">
      <div>
      <Button title="VIRAR HORIZONTALMENTE" name="flip-h" handleClick={ () => handleFlipH() }><IconFlipH /></Button>
      </div>

      <div>
      <Button title="VIRAR VERTICALMENTE" name="flip-v" handleClick={ () => handleFlipV() }><IconFlipV /></Button>
      </div>

      <div>
      <Button title="ENVIAR PARA FRENTE" name="bring-fwd" handleClick={ () => handleBringFwd() }><IconBringFwd /></Button>
      </div>
      
      <div>
      <Button title="ENVIAR PARA TRÁS" name="bring-back" handleClick={ () => handleBringBack() }><IconBringBack /></Button>
      </div>
      
      <div>
      <Button title="DUPLICAR" name="duplicate" handleClick={ () => handleDuplicate() }><IconDuplicate /></Button>
      </div>
      
      <div>
      <Button title="REMOVER" name="remove" handleClick={ () => handleDel() }><IconRemove /></Button>
      </div>

      <div>
      <Button title="AGRUPAR" name="group" handleClick={ () => handleGroup() }><IconGroup /></Button>
      </div>
      
      <div>
      <Button title="DESAGRUPAR" name="ungroup" handleClick={ () => handleUngroup() }><IconUngroup /></Button>
      </div>
      
    </div>
  )

}

export default SelectionObjectSettings
