/*
 * SVG Editor
 * version: 1.0.1
 *
 */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Notification from './components/Notification';
import Header from './components/Header';
import Button from './components/Button';
import FloatingMenu from './components/FloatingMenu';
import Menu from './components/Menu';
import Toolbar from './components/Toolbar';
import FabricCanvas from './components/FabricCanvas';
import ToolPanel from './components/ToolPanel';
import SelectionSettings from './components/SelectionSettings';
import SelectionSettingsIframe from './components/SelectionSettingsIframe';
import CanvasSettings from './components/CanvasSettings';
import DrawSettings from './components/DrawSettings';
import Shapes from './components/Shapes';
import UploadSettings from './components/UploadSettings';
//import LayerParent from './components/LayerParent';

//RAFAEL
import BackgroundScene from './components/BackgroundScene';
import ChangeSport from './components/ChangeSport';
import UploadCustom from './components/UploadCustom';
import Video from './components/Video';
import Animation from './components/Animation';
import Material from './components/Material';
import Download from './components/Download';
import Annotation from './components/Annotation';
import Cloth from './components/Cloth';
import Patterns from './components/Patterns';
import { InputSelect, Option } from './components/InputSelect';

import __ from './utils/translation';
import saveInBrowser from './utils/saveInBrowser';
import { downloadImage, downloadSVG } from './utils/downloadImage';
import { undo, redo } from './utils/undoRedo';
import { editorTips } from './utils/editorTips';
import { handleDrawingModes } from './utils/handleDrawingModes';
import { applyZoom, zoomWithKeys, zoomWithMouse, zoomOptions } from './utils/zoom';

import logo from './images/logo.png';
import { ReactComponent as IconGear } from './icons/gear.svg';
import { ReactComponent as IconUndo } from './icons/undo.svg';
import { ReactComponent as IconRedo } from './icons/redo.svg';
import { ReactComponent as IconTick } from './icons/tick.svg';
import { ReactComponent as IconDownload } from './icons/down.svg';
import { ReactComponent as IconClose } from './icons/close.svg';
import { ReactComponent as IconBrush } from './icons/brush.svg';
import { ReactComponent as IconCursor } from './icons/cursor.svg';
import { ReactComponent as IconLine } from './icons/line.svg';
import { ReactComponent as IconPath } from './icons/path.svg';
import { ReactComponent as IconShape } from './icons/shape.svg';
import { ReactComponent as IconText } from './icons/text.svg';
import { ReactComponent as IconUpload } from './icons/upload.svg';
import { ReactComponent as IconZoom } from './icons/zoom.svg';
import { ReactComponent as IconWallPaper } from './icons/wallpaper.svg';

import { ReactComponent as IconDownload2 } from './icons/file_download.svg';
import { ReactComponent as IconUpload2 } from './icons/file_upload.svg';
import { ReactComponent as IconAR } from './icons/view_in_ar.svg';

import { ReactComponent as IconStudio } from './icons/studio.svg';
import { ReactComponent as IconSports } from './icons/sports.svg';
import { ReactComponent as IconVideo } from './icons/video.svg';
import { ReactComponent as IconCloth } from './icons/cloth.svg';
import { ReactComponent as IconAnimation } from './icons/animation.svg';
import { ReactComponent as IconEdit } from './icons/edit.svg';
import { ReactComponent as IconStyler } from './icons/styler.svg';
import { ReactComponent as IconPlay } from './icons/play.svg';
import { ReactComponent as IconBoy } from './icons/boy.svg';
import { ReactComponent as IconCamera } from './icons/flip_camera_android_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconBody } from './icons/metabolism_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconAutoRenew } from './icons/autorenew.svg';
import { ReactComponent as IconArrowBack } from './icons/arrow_back_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as IconGraph } from './icons/account_tree_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as IconLogOut } from './icons/power_settings_new_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconSave } from './icons/save_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconFolder } from './icons/folder_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconColor } from './icons/palette_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconList } from './icons/receipt_long_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconAnnotation } from './icons/edit_note_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconColorPattern } from './icons/format_color_fill_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconPattenrs } from './icons/view_kanban_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconResolution } from './icons/6k_FILL0_wght400_GRAD0_opsz48.svg';
import { useSignOut } from "react-auth-kit";
import { useIsAuthenticated } from 'react-auth-kit';
import $ from "jquery";
import axios, { AxiosError } from "axios";

import './SimulatorIframe.scss';

//
const SimulatorIframe = () => {

  window.canvasLoaded = false;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var is_iframe = urlParams.get('i');

  import('./editorIframe.js')
    .then((module) => {
      if (window.name == "not-logged") {
        window.name = "logged";
        window["initSimulatorIframe"]();
      }
    });

  // states
  const [notification, setNotification] = useState({ message: null, type: null, seconds: null })
  const [downloadMenuVisible, setDownloadMenuVisible] = useState(false)
  const [activeTool, setActiveTool] = useState('select')

  const [canvas, setCanvas] = useState()
  const [loadSavedCanvas, setLoadSavedCanvas] = useState(true)
  const [activeSelection, setActiveSelection] = useState(null)
  const [history, setHistory] = useState({ index: null, states: [] })
  const [selectionInfo, setSelectionInfo] = useState(editorTips[Math.floor(Math.random() * editorTips.length)])
  const [zoom, setZoom] = useState(1)

  //--------------------------------------------------------------------


  // on start: check if there is a saved canvas in this browser and ask if we should load it
  useEffect(() => {
    if (canvas && loadSavedCanvas) {
      const savedCanvas = saveInBrowser.load('canvasEditor');
      if (savedCanvas && window.confirm(__('We found a project saved in this browser! Do you want to load it?'))) {
        canvas.loadFromJSON(savedCanvas, canvas.renderAll.bind(canvas));
      }

      setLoadSavedCanvas(false);
    }
  }, [canvas, loadSavedCanvas])


  //--------------------------------------------------------------------


  // on active selection update: change active tool to select
  useEffect(() => {
    if (!activeSelection) return

    //setActiveTool('select')

    // scroll to top in tool panel
    document.querySelector('.toolpanel .holder').scrollTop = 0
  }, [activeSelection])


  //--------------------------------------------------------------------


  // on active tool change: deselect all object, handle drawing modes
  useEffect(() => {
    if (!canvas) return

    if (activeTool !== 'select') canvas.discardActiveObject().requestRenderAll()

    handleDrawingModes(canvas, activeTool, setSelectionInfo)
  }, [canvas, activeTool])


  //--------------------------------------------------------------------


  // save history and unsaved work alert
  const maxHistory = 10
  useEffect(() => {
    if (!canvas) return

    const saveHistory = () => {
      let updatedHistory = [...history.states]

      // if any action happens after undo, clear all (redo) actions after current state
      if (history.index < history.states.length - 1) updatedHistory.splice(history.index + 1)

      // add current state to history
      updatedHistory.push(canvas.toJSON(['lockMovementX', 'lockMovementY', 'lockRotation', 'lockScalingX', 'lockScalingY',
        'hasControls', 'default', 'id', 'scaleX', 'scaleY']))
      if (updatedHistory.length > maxHistory) updatedHistory.shift()

      setHistory({ index: updatedHistory.length - 1, states: updatedHistory })
    }
    canvas.on('object:modified', saveHistory)
    canvas.on('path:created', saveHistory)


    const unsavedWorkAlert = (e) => {
      if (history.states.length > 1) e.returnValue = __(`Are you sure you want to leave?`)
    }
    window.addEventListener('beforeunload', unsavedWorkAlert)


    // cleanup
    return () => {
      canvas.off('object:modified', saveHistory)
      canvas.off('path:created', saveHistory)

      window.removeEventListener('beforeunload', unsavedWorkAlert)
    }
  }, [canvas, history])


  //--------------------------------------------------------------------


  // keyboard & mouse shortcuts
  useEffect(() => {
    if (!canvas) return

    // select tool (v)
    const keyV = (e) => {
      const key = e.which || e.keyCode;
      if (key === 86 && document.querySelectorAll('textarea:focus, input:focus').length === 0) {
        //setActiveTool('select')
      }
    }
    document.addEventListener('keydown', keyV)


    // undo/redo (ctrl z/y)
    const ctrZY = (e) => {
      const key = e.which || e.keyCode;

      if (key === 90 && e.ctrlKey && document.querySelectorAll('textarea:focus, input:focus').length === 0) {
        undo(canvas, history, setHistory)
      }

      if (key === 89 && e.ctrlKey && document.querySelectorAll('textarea:focus, input:focus').length === 0) {
        redo(canvas, history, setHistory)
      }
    }
    document.addEventListener('keydown', ctrZY)


    // zoom out/in/reset (ctr + -/+/0)
    const keyZoom = (e) => zoomWithKeys(e, canvas, setZoom, applyZoom)
    document.addEventListener('keydown', keyZoom)


    // zoom out/in with mouse
    const mouseZoom = (e) => zoomWithMouse(e, canvas, setZoom, applyZoom)
    document.addEventListener('wheel', mouseZoom, { passive: false })


    // cleanup
    return () => {
      document.removeEventListener('keydown', keyV)
      document.removeEventListener('keydown', ctrZY)
      document.removeEventListener('keydown', keyZoom)
      document.removeEventListener('wheel', mouseZoom)
    }
  }, [canvas, history])

  //--------------------------------------------------------------------

  // render layout
  return (
    <div id="app" className='iframe'>

      <div className='toolpanel_panel' id="ChangeSport">
        <ChangeSport canvas={canvas} />
      </div>

      <div id="container-iframe">

      </div>

      <div id="sidebar">
        <button id="sidebar-expand">
          <i className="fas fa-chevron-right"></i>
        </button>
        <div className='header flex-horizontal'>
          <button id="sidebar-menu">
            <i className="fas fa-chevron-left"></i>
          </button>
          <span id="iframe-title" className='lang' data-en="Configurator" data-pt="Configurador" data-es="Configurador">Configurator</span>

          <i className="fas fa-plus orderSvg" id="order-plus"></i>
          <i className="fas fa-minus orderSvg" id="order-minus"></i>
        </div>
        <div className='body'>
          <div data-obj="1" className='option-body-iframe' data-id="design-options-iframe" data-en="Models" data-pt="Modelos" data-es="Modelos">
            <div className='flex-horizontal'>
              <i className="fas fa-tshirt"></i>
            </div>
            <span className='lang' data-en="Models" data-pt="Modelos" data-es="Modelos">Models</span>
          </div>
          <div data-obj="2" className='option-body-iframe' data-id="color-options-iframe" data-en="Colors" data-pt="Cores" data-es="Colores">
            <div className='flex-horizontal'>
              <i className="fas fa-palette"></i>
            </div>
            <span className='lang' data-en="Colors" data-pt="Cores" data-es="Colores">Colors</span>
          </div>
          <div data-obj="3" className='option-body-iframe' data-id="logo-options-iframe" data-en="Logo" data-pt="Logo" data-es="Logo">
            <div className='flex-horizontal'>
              <i className="fas fa-image"></i>
            </div>
            <span>Logo</span>
          </div>
          <div data-obj="4" className='option-body-iframe' data-id="text-options-iframe" data-en="Text" data-pt="Texto" data-es="Texto">
            <div className='flex-horizontal'>
              <i className="fas fa-font"></i>
            </div>
            <span className='lang' data-en="Text" data-pt="Texto" data-es="Texto">Text</span>
          </div>

          <div data-obj="5" className='option-body-iframe' data-id="visibility-options-iframe" data-en="Visibility" data-pt="Visibilidade" data-es="Visibilidade">
            <div className='flex-horizontal'>
              <i className="fas fa-eye"></i>
            </div>
            <span className='lang' data-en="Visibility" data-pt="Visibilidade" data-es="Visibilidade">Visibilidade</span>
          </div>

          {/**<div data-obj="5" className='option-body-iframe' data-en="Clipart" data-pt="Clipart" data-es="Clipart">
            <div className='flex-horizontal'>
              <i className="fas fa-file-image"></i>
            </div>
            <span>Clipart</span>
          </div>

          <div data-obj="5" className='option-body-iframe' data-en="Fabric" data-pt="Tecido" data-es="Tela">
            <div className='flex-horizontal'>
              <i className="fas fa-magic"></i>
            </div>
            <span className='lang' data-en="Fabric" data-pt="Tecido" data-es="Tejido">Fabric</span>
          </div> */}

          <div className='sub-options-body-iframe' id="color-options-iframe">
            <div id="btn-colors-options-iframe" className='flex-horizontal'>
              <button id="iframe-color-selection" className='lang' data-en="Selection" data-pt="Seleção" data-es="Selección">Selection</button>
              <button id="iframe-color-auto" className='lang' data-en="Automatic" data-pt="Automático" data-es="Automático">Automatic</button>
            </div>
            <span className='flex-horizontal lang color-iframe color-iframe-select'
              data-en="Select the part of the object you want to change the color"
              data-pt="Selecione a parte do objeto cuja cor deseja alterar"
              data-es="Seleccione la parte del objeto cuyo color desea cambiar.">Select the part of the object you want to change the color</span>
            <div id="grouped-colors" className='flex-horizontal color-iframe'>
              <div id='grouped-colors-buttons' className='flex-horizontal'>
                <button id="addColorSvgIframe" className='lang' data-en="Update Colors" data-pt="Atualizar cores" data-es="Actualizar colores">Update Colors</button>
                <button id="randomColorSvgIframe" className='lang' data-en="Random Colors" data-pt="Cores Aleatórias" data-es="Colores aleatorios">Random Colors</button>
              </div>
              <span className='lang' data-en="Colors from the SVG" data-pt="Cores do SVG" data-es="Colores del SVG">Colors from the SVG</span>
              <div id='grouped-colors-list'></div>
            </div>
            <div id="color-options-iframe-table-main" className='color-iframe'>
              <span className='lang' data-en="Choose the new color:" data-pt="Escolha a nova cor:" data-es="Elige el nuevo color:">Choose the new color:</span>
              <div id="color-options-iframe-table"></div>
            </div>
          </div>

          <div className='sub-options-body-iframe' id="design-options-iframe">
            <div id="iframe-desig-pages" className='flex-horizontal'></div>
            <div id="design-options-iframe-list"></div>
          </div>

          <div className='sub-options-body-iframe' id="visibility-options-iframe">
            <div id="options-general">
              <div className='option-general' id="obj-state-body">
                <span className='lang' data-en="Body" data-pt="Corpo" data-es="Cuerpo">Body:</span>
                <div className='value-general'>
                  <input defaultChecked id="state-body-ra" data-type="corpo" className='obj-type' type='checkbox'></input>
                </div>
              </div>
              <div className='option-general'>
                <span className='lang' data-en="Socks" data-pt="Meias" data-es="Meias">Socks:</span>
                <div className='value-general'>
                  <input id="state-socks-ra" defaultChecked data-type="meias" className='obj-type' type='checkbox'></input>
                </div>
              </div>
              {/**<div className='option-general'>
                <span className='lang' data-en="Shirt" data-pt="Camisa" data-es="Camisa">Shirt:</span>
                <div className='value-general'>
                  <input id='state-shirt-ra' defaultChecked data-type="camisa" className='obj-type' type='checkbox'></input>
                </div>
              </div> */}
              <div className='option-general'>
                <span>Shorts:</span>
                <div className='value-general'>
                  <input id="state-short-ra" defaultChecked data-type="shorts" className='obj-type' type='checkbox'></input>
                </div>
              </div>
            </div>
          </div>



          <div className='sub-options-body-iframe' id="logo-options-iframe">

            <div id="logo-main">
              <div id="logo-sub-1">
                <span className='warning flex-horizontal lang' data-en="You can only move logos while in this panel!" data-pt="Você só pode mover logotipos neste painel!" data-es="¡Solo puedes mover logotipos mientras estás en este panel!">You can only move logos while in this panel!</span>
                <div className='range-logo flex-horizontal'>
                  <span className='lang' data-en="Rotation" data-pt="Rotação" data-es="Rotación">Rotation</span>
                  <input id="logo-iframe-rot" min={0} max={360} type='range'></input>
                </div>
                <div className='range-logo flex-horizontal'>
                  <span className='lang' data-en="Scale" data-pt="Escala" data-es="Escala">Scale</span>
                  <input step={0.05} id="logo-iframe-scale" min={0.2} max={10} defaultValue={1} type='range'></input>
                </div>
                <div className="drop-zone">
                  <span className="drop-zone__prompt lang"
                    data-en="Drop file here or click to upload! After that, drag the logo to the point in the 3d where you want to place it."
                    data-pt="Solte o arquivo aqui ou clique para fazer upload! Depois disso, arraste o logotipo até o ponto 3D onde deseja colocá-lo."
                    data-es="¡Suelte el archivo aquí o haga clic para cargarlo! Después de eso, arrastre el logotipo hasta el punto en 3d donde desea colocarlo.">
                    Drop file here or click to upload! After that, drag the logo to the point in the 3d where you want to place it.</span>
                  <input type="file" name="myFile" className="drop-zone__input"></input>
                </div>
              </div>
              <div id="logo-sub-2">
                <div id='list-logos-iframe'>
                </div>
              </div>

            </div>
          </div>

          <div className='sub-options-body-iframe' id="text-options-iframe">

            <div id="table-color-txt">
              <button>
                <i className="fas fa-chevron-left"></i>
              </button>
            </div>

            <div id="text-options-iframe-panel" className='flex-horizontal'>
              <span className='lang warning'
                data-en="Add a text or click in a text on the model you want to make changes to. You can also move the text while in this panel by dragging on the model."
                data-pt="Adicione um texto ou clique em um texto no modelo no qual deseja fazer alterações. Você também pode mover o texto neste painel arrastando o modelo."
                data-es="Agregue un texto o haga clic en un texto en el modelo al que desea realizar cambios. También puedes mover el texto mientras estás en este panel arrastrando el modelo.">
                Add a text or click in a text on the model you want to make changes to. You can also move the text while in this panel by dragging on the model.</span>
              <button id="add-txt-iframe" className='lang' data-en="ADD TEXT" data-pt="ADICIONAR TEXTO" data-es="AÑADIR TEXTO">ADD TEXT</button>

              <div className='range-txt flex-horizontal'>
                <span className='lang' data-en="Font" data-pt="Fonte" data-es="Fuente">Font Family</span>
                <div className="input-select">
                  <div className="select" id='select-font-iframe'>
                    <div className="active-option" id="active-option-font-iframe">Escolher</div>
                    <div className="list" id='list-font-iframe'>


                      <div data-path="fonts/NeueMontreal-BoldItalic/" data-extension="otf" className="font1" data-value="'NeueMontreal-BoldItalic'">NeueMontreal-BoldItalic</div>
                      <div data-path="fonts/" data-extension="otf" className="font2" data-value="aAngkatanBersenjata">aAngkatanBersenjata</div>
                      <div data-path="fonts/" data-extension="otf" className="font3" data-value="aAlloyInk">aAlloyInk</div>
                      <div data-path="fonts/" data-extension="otf" className="font4" data-value="aAbstractGroovy">aAbstractGroovy</div>
                      <div data-path="fonts/" data-extension="otf" className="font5" data-value="aAnotherTag">aAnotherTag</div>
                      <div data-path="fonts/" data-extension="otf" className="font6" data-value="aAntaraDistance">aAntaraDistance</div>
                      <div data-path="fonts/" data-extension="ttf" className="font7" data-value="aArigatouGozaimasu">aArigatouGozaimasu</div>
                      <div data-path="fonts/" data-extension="otf" className="font8" data-value="aAttackGraffiti">aAttackGraffiti</div>
                      <div data-path="fonts/" data-extension="otf" className="font9" data-value="aAutobusOmnibus">aAutobusOmnibus</div>
                      <div data-path="fonts/" data-extension="otf" className="font10" data-value="Alphakind">Alphakind</div>
                      <div data-path="fonts/" data-extension="otf" className="font11" data-value="AromiaScript-Black_DONATIONWARE">AromiaScript-Black_DONATIONWARE</div>
                      <div data-path="fonts/" data-extension="otf" className="font12" data-value="Atmos">Atmos</div>
                      <div data-path="fonts/" data-extension="ttf" className="font13" data-value="AURORA-Regular">AURORA-Regular</div>
                      <div data-path="fonts/" data-extension="otf" className="font14" data-value="Automobile Contest">Automobile Contest</div>
                      <div data-path="fonts/" data-extension="otf" className="font15" data-value="Bekasi">Bekasi</div>
                      <div data-path="fonts/" data-extension="ttf" className="font16" data-value="BrazierFlame">BrazierFlame</div>
                      <div data-path="fonts/" data-extension="otf" className="font17" data-value="CastawayFill v2">CastawayFill v2</div>
                      <div data-path="fonts/" data-extension="ttf" className="font18" data-value="Catamaran">Catamaran</div>
                      <div data-path="fonts/" data-extension="ttf" className="font19" data-value="Coconut House">Coconut House</div>
                      <div data-path="fonts/" data-extension="otf" className="font20" data-value="FatMax-Regular">FatMax-Regular</div>
                      <div data-path="fonts/" data-extension="otf" className="font21" data-value="FREEZONE">FREEZONE</div>
                      <div data-path="fonts/" data-extension="otf" className="font22" data-value="Grumaz-Regular">Grumaz-Regular</div>
                      <div data-path="fonts/" data-extension="ttf" className="font23" data-value="KawitFree-CndItalic">KawitFree-CndItalic</div>
                      <div data-path="fonts/" data-extension="ttf" className="font24" data-value="Lavindir">Lavindir</div>
                      <div data-path="fonts/" data-extension="ttf" className="font25" data-value="LTBulletin-Medium">LTBulletin-Medium</div>
                      <div data-path="fonts/" data-extension="otf" className="font26" data-value="LullabiestByHttpsgoths-Regular">LullabiestByHttpsgoths-Regular</div>
                      <div data-path="fonts/" data-extension="ttf" className="font27" data-value="MakerVan-Regular">MakerVan-Regular</div>
                      <div data-path="fonts/" data-extension="ttf" className="font28" data-value="NCS Rogueland Slab Bold">NCS Rogueland Slab Bold</div>
                      <div data-path="fonts/" data-extension="ttf" className="font29" data-value="Number9">Number9</div>
                      <div data-path="fonts/" data-extension="otf" className="font30" data-value="PixoRetoSP-Regular">PixoRetoSP-Regular</div>
                      <div data-path="fonts/" data-extension="otf" className="font31" data-value="Rehat">Rehat</div>
                      <div data-path="fonts/" data-extension="ttf" className="font32" data-value="Shock">Shock</div>
                      <div data-path="fonts/" data-extension="ttf" className="font33" data-value="Talisman">Talisman</div>
                      <div data-path="fonts/" data-extension="ttf" className="font34" data-value="teefont">teefont</div>
                      <div data-path="fonts/" data-extension="otf" className="font35" data-value="WagoonBoldInline">WagoonBoldInline</div>

                      <div data-path="fonts/fontsFutebol/" data-extension="otf" className="font36" data-value="BaysideFont-Regular-Demo">BaysideFont-Regular-Demo</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font37" data-value="BEST-IN-CLASS">BEST-IN-CLASS</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="otf" className="font38" data-value="CoubraFont-Demo">CoubraFont-Demo</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font39" data-value="Fut-1">Fut-1</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font40" data-value="Fut1">Fut1</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font41" data-value="Fut-3">Fut-3</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font42" data-value="fut-4">fut-4</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font43" data-value="fut-5<">fut-5</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font44" data-value="fut-6">fut-6</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font45" data-value="Fut-7">Fut-7</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font46" data-value="fut-8">fut-8</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font47" data-value="fut-9">fut-9</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font48" data-value="fut-10">fut-10</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font49" data-value="fut-11">fut-11</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font50" data-value="fut-12">fut-12</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font51" data-value="fut-13">fut-13</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font52" data-value="fut-14">fut-14</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font53" data-value="fut-15">fut-15</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font54" data-value="fut-16">fut-16</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="otf" className="font55" data-value="HighriseFont-Bold-Demo">HighriseFont-Bold-Demo</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font56" data-value="KAMIKZOM">KAMIKZOM</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="otf" className="font57" data-value="Miamagon">Miamagon</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="otf" className="font58" data-value="NewakeFont-Demo">NewakeFont-Demo</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font59" data-value="Russo_One">Russo_One</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font60" data-value="TESLA">TESLA</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font61" data-value="urae_nium">urae_nium</div>
                      <div data-path="fonts/fontsFutebol/" data-extension="ttf" className="font62" data-value="Velocista-Demo-FFP">Velocista-Demo-FFP</div>

                      {/**NEW FONTS */}
                      <div data-path="novas/" data-extension="ttf" className="font63" data-value="Alemanha2014">Alemanha2014</div>
                      <div data-path="novas/" data-extension="ttf" className="font64" data-value="ASRoma2017-18">ASRoma2017-18</div>
                      <div data-path="novas/" data-extension="ttf" className="font65" data-value="AtleticoMadrid2013-2014">AtleticoMadrid2013-2014</div>
                      <div data-path="novas/" data-extension="ttf" className="font66" data-value="Barcelona19-20">Barcelona19-20</div>
                      <div data-path="novas/" data-extension="ttf" className="font67" data-value="Barcelona2013">Barcelona2013</div>
                      <div data-path="novas/" data-extension="ttf" className="font68" data-value="BarcelonaNike2018-19">BarcelonaNike2018-19</div>
                      <div data-path="novas/" data-extension="ttf" className="font69" data-value="bayern_munchen_2021_fonts593">bayern_munchen_2021_fonts593</div>
                      <div data-path="novas/" data-extension="ttf" className="font70" data-value="Borussia2018">Borussia2018</div>
                      <div data-path="novas/" data-extension="ttf" className="font71" data-value="BorussiaDortmund2018">BorussiaDortmund2018</div>
                      <div data-path="novas/" data-extension="ttf" className="font72" data-value="BrasilQatar2022">BrasilQatar2022</div>
                      <div data-path="novas/" data-extension="ttf" className="font73" data-value="Chelsea2019-2020">Chelsea2019-2020</div>
                      <div data-path="novas/" data-extension="ttf" className="font74" data-value="ChelseaNike2017-18">ChelseaNike2017-18</div>
                      <div data-path="novas/" data-extension="ttf" className="font75" data-value="ClubAmerica2019-20">ClubAmerica2019-20</div>
                      <div data-path="novas/" data-extension="ttf" className="font76" data-value="ClubAmerica2021-2022">ClubAmerica2021-2022</div>
                      <div data-path="novas/" data-extension="ttf" className="font77" data-value="Croatia2021Fonts593">Croatia2021Fonts593</div>
                      <div data-path="novas/" data-extension="ttf" className="font78" data-value="EcuadorFont2022">EcuadorFont2022</div>
                      <div data-path="novas/" data-extension="ttf" className="font79" data-value="france_2020_2021-Fonts593">france_2020_2021-Fonts593</div>
                      <div data-path="novas/" data-extension="ttf" className="font80" data-value="Holanda2014">Holanda2014</div>
                      <div data-path="novas/" data-extension="ttf" className="font81" data-value="Holanda2018">Holanda2018</div>
                      <div data-path="novas/" data-extension="ttf" className="font82" data-value="Inglaterra2014-2015">Inglaterra2014-2015</div>
                      <div data-path="novas/" data-extension="ttf" className="font83" data-value="Inglaterra2018-2019">Inglaterra2018-2019</div>
                      <div data-path="novas/" data-extension="ttf" className="font84" data-value="inter_milanfont_2020-2021-Fonts593">inter_milanfont_2020-2021-Fonts593</div>
                      <div data-path="novas/" data-extension="ttf" className="font85" data-value="InterMilan2011">InterMilan2011</div>
                      <div data-path="novas/" data-extension="ttf" className="font86" data-value="InterMilan2018-19">InterMilan2018-19</div>
                      <div data-path="novas/" data-extension="ttf" className="font87" data-value="Juventus2014-2015">Juventus2014-2015</div>
                      <div data-path="novas/" data-extension="ttf" className="font88" data-value="Juventus2018-2019">Juventus2018-2019</div>
                      <div data-path="novas/" data-extension="ttf" className="font89" data-value="Ligue1PSG2020-2021">Ligue1PSG2020-2021</div>
                      <div data-path="novas/" data-extension="ttf" className="font90" data-value="ManchesterCity2021-2022">ManchesterCity2021-2022</div>
                      <div data-path="novas/" data-extension="ttf" className="font91" data-value="ManchesterCity2017-2018">ManchesterCity2017-2018</div>
                      <div data-path="novas/" data-extension="ttf" className="font92" data-value="ManchesterUnited2015-2016">ManchesterUnited2015-2016</div>
                      <div data-path="novas/" data-extension="ttf" className="font93" data-value="ManchesterUnited2017-2018">ManchesterUnited2017-2018</div>
                      <div data-path="novas/" data-extension="ttf" className="font94" data-value="Milan2015-2016">Milan2015-2016</div>
                      <div data-path="novas/" data-extension="ttf" className="font95" data-value="Milan2017-2018">Milan2017-2018</div>
                      <div data-path="novas/" data-extension="ttf" className="font96" data-value="MLSMajorLeagueSoccer2020">MLSMajorLeagueSoccer2020</div>
                      <div data-path="novas/" data-extension="ttf" className="font97" data-value="Netherlands2018">Netherlands2018</div>
                      <div data-path="novas/" data-extension="ttf" className="font98" data-value="Nigeria2020-2021">Nigeria2020-2021</div>
                      <div data-path="novas/" data-extension="ttf" className="font99" data-value="Portugal2014-2015">Portugal2014-2015</div>
                      <div data-path="novas/" data-extension="ttf" className="font100" data-value="PSGFont2021-2022">PSGFont2021-2022</div>
                      <div data-path="novas/" data-extension="ttf" className="font101" data-value="RealMadrid2014-15">RealMadrid2014-15</div>
                      <div data-path="novas/" data-extension="ttf" className="font102" data-value="RealMadrid2015">RealMadrid2015</div>
                      <div data-path="novas/" data-extension="ttf" className="font103" data-value="RealMadrid2017-2018">RealMadrid2017-2018</div>
                      <div data-path="novas/" data-extension="ttf" className="font104" data-value="RealMadrid2021-22">RealMadrid2021-22</div>
                      <div data-path="novas/" data-extension="ttf" className="font105" data-value="RealMadridAdidas2018-19">RealMadridAdidas2018-19</div>
                      <div data-path="novas/" data-extension="ttf" className="font106" data-value="reamadrid-2020-2021-Fonts593">reamadrid-2020-2021-Fonts593</div>
                      <div data-path="novas/" data-extension="ttf" className="font107" data-value="Roma2015-16">Roma2015-16</div>
                      <div data-path="novas/" data-extension="ttf" className="font108" data-value="RomaNike2018-19">RomaNike2018-19</div>
                      <div data-path="novas/" data-extension="ttf" className="font109" data-value="south_korea_2020-2021-Fonts593">south_korea_2020-2021-Fonts593</div>
                      <div data-path="novas/" data-extension="ttf" className="font110" data-value="TolucaUnder2018-2019">TolucaUnder2018-2019</div>
                      <div data-path="novas/" data-extension="ttf" className="font111" data-value="USANationalTeam2020">USANationalTeam2020</div>
                      <div data-path="novas/" data-extension="ttf" className="font112" data-value="USA-2018">USA-2018</div>
                      <div data-path="novas/" data-extension="ttf" className="font113" data-value="vasco2016-2017">vasco2016-2017</div>
                      <div data-path="novas/" data-extension="ttf" className="font114" data-value="wolfsburg_2020_2021_fonts593-Fonts593">wolfsburg_2020_2021_fonts593-Fonts593</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='range-txt flex-horizontal'>
                <span className='lang' data-en="Text" data-pt="Conteúdo" data-es="Contenido">Content</span>
                <input id="txt-iframe-text" type='text'></input>
              </div>
              <div className='range-txt flex-horizontal'>
                <span className='lang' data-en="Text Color" data-pt="Cor do Texto" data-es="Color del Text">Text Color</span>
                <button id="txt-color-picker"></button>
              </div>
              <div className='range-txt flex-horizontal'>
                <span className='lang' data-en="Border" data-pt="Borda" data-es="Borde">Border</span>
                <input id="txt-iframe-border" type='number' min={0} max={10} step={0.1}></input>
              </div>
              <div className='range-txt flex-horizontal'>
                <span className='lang' data-en="Border Color" data-pt="Cor da Borda" data-es="Color del borde">Border Color</span>
                <button id="txt-border-color-picker"></button>
              </div>
              <div className='range-txt flex-horizontal'>
                <span className='lang' data-en="Rotation" data-pt="Rotação" data-es="Rotación">Rotation</span>
                <input id="txt-iframe-rot" min={0} max={360} type='range'></input>
              </div>
              <div className='range-txt flex-horizontal'>
                <span className='lang' data-en="Scale" data-pt="Escala" data-es="Escala">Scale</span>
                <input step={0.01} id="txt-iframe-scale" min={0.1} max={10} defaultValue={1} type='range'></input>
              </div>

            </div>
          </div>


        </div>
        {/**<div className='bottom flex-horizontal'>
          <button className='lang' data-en="SENT" data-pt="ENVIAR" data-es="ENVIAR">SEND</button>
        </div> */}
      </div>

      <FabricCanvas canvas={canvas} setCanvas={setCanvas}
        selectionInfo={selectionInfo} setSelectionInfo={setSelectionInfo}
        setActiveSelection={setActiveSelection}
        setHistory={setHistory} />

      <div id="loading">
        <div className="container2">
          <div id='loader2'>
            <div id='title2' className='flex2'>
              <p className='loading-text2'>LOADING</p>
              <div className='therefore2'>∴</div>
              <p className="loading-number2"></p>
            </div>
            <div id='loading-bar-border2'>
              <div className='loading-bar2'>
              </div>
            </div>
            <div id='warning2'>
              <p></p>
              <div className='exclamation2'>!</div>
              CAUTION, Do not turn off.
              <div id='line-cascates2'></div>
            </div>
          </div>
        </div>
      </div>

      <div id="holding_img_iframe" className='btn-iframe'></div>

      <div id="iframe-language" className='btn-iframe'></div>

      {/**<div id="ar-option" className='btn-iframe'>
        <i className="fas fa-vr-cardboard"></i>
      </div> */}

      <div id="send-iframe" className='btn-iframe'>
        <i className="fas fa-paper-plane"></i>
      </div>

      <div id="full-iframe" className='btn-iframe'>
        <i className="fas fa-expand"></i>
      </div>

    </div>
  )
}

export default SimulatorIframe
