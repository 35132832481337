import React, { useState, useEffect } from 'react';
import './Annotation.scss';
import __ from './../utils/translation';
import $ from "jquery";
import { downloadImage, downloadSVG } from '../utils/downloadImage';
import { applyZoom, zoomWithKeys, zoomWithMouse, zoomOptions } from '../utils/zoom';
import { ReactComponent as IconDownload2 } from '../icons/file_download.svg';

const ObjState = ({ canvas }) => {

    return (
        <>
            <p className="title lang" data-en="PARTS VISIBILITY" data-pt="VISIBILIDADE DAS PARTES" data-es="VISIBILIDAD DE PIEZAS">{__('PARTS VISIBILITY')}</p>

            <div id="options-general">

                <div className='option-general' id="obj-state-body">
                    <span className='lang' data-en="Body" data-pt="Corpo" data-es="Cuerpo">Body:</span>
                    <div className='value-general'>
                        <input defaultChecked id="state-body-ra" data-type="avatar" className='obj-type' type='checkbox'></input>
                    </div>
                </div>
                {/**<div className='option-general'>
                    <span>Sapatos:</span>
                    <div className='value-general'>
                        <input data-type="sapato" className='obj-type' type='checkbox'></input>
                    </div>
                </div> */}
                <div className='option-general'>
                    <span className='lang' data-en="Socks" data-pt="Meias" data-es="Meias">Socks:</span>
                    <div className='value-general'>
                        <input id="state-socks-ra" defaultChecked data-type="meia" className='obj-type' type='checkbox'></input>
                    </div>
                </div>
                {/**<div className='option-general'>
                    <span className='lang' data-en="Shirt" data-pt="Camisa" data-es="Camisa">Shirt:</span>
                    <div className='value-general'>
                        <input id='state-shirt-ra' defaultChecked data-type="camisa" className='obj-type' type='checkbox'></input>
                    </div>
                </div> */}
                <div className='option-general'>
                    <span>Shorts:</span>
                    <div className='value-general'>
                        <input id="state-short-ra" defaultChecked data-type="short" className='obj-type' type='checkbox'></input>
                    </div>
                </div>

                {/**<div className='option-general'>
                    <span className='lang' data-en="Shadows" data-pt="Sombras" data-es="Oscuridad">Shadow:</span>
                    <div className='value-general'>
                        <input data-type="shadow" className='' id="shadow-state" type='checkbox'></input>
                    </div>
                </div> */}

                <div className='option-general poses' id="pose1">
                    <span>Pose 1:</span>
                    <div className='value-general'>
                        <input defaultChecked data-type="pose" data-id="0" className='obj-type pose-input' type='checkbox'></input>
                    </div>
                </div>
                <div className='option-general poses' id="pose2">
                    <span>Pose 2:</span>
                    <div className='value-general'>
                        <input data-type="pose" className='obj-type pose-input' data-id="1" type='checkbox'></input>
                    </div>
                </div>
                <div className='option-general poses' id="pose3">
                    <span>Pose 3:</span>
                    <div className='value-general'>
                        <input data-type="pose" className='obj-type pose-input' data-id="2" type='checkbox'></input>
                    </div>
                </div>
                <div className='option-general poses' id="pose4">
                    <span>Pose 4:</span>
                    <div className='value-general'>
                        <input data-type="pose" className='obj-type pose-input' data-id="3" type='checkbox'></input>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default ObjState