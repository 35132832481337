import React from 'react';
import './ChangeSport.scss';
import __ from './../utils/translation';
import $ from "jquery";

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles('dark', {
        backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ChangeSport = ({ canvas }) => {

    const [expanded, setExpanded] = React.useState('panel1');
    const [selectedValue, setSelectedValue] = React.useState('a');
    const [selectedBaseValue, setSelectedBaseValue] = React.useState('tradicional');
    const [selectedGolaValue, setSelectedGolaValue] = React.useState('Arsenal');
    const [selectedMangaValue, setSelectedMangaValue] = React.useState('curta');
    const [selectedPoseValue, setSelectedPoseValue] = React.useState('0');
    const [selectedShortValue, setSelectedShortValue] = React.useState('neutra');
    const [selectedBaseCorteValue, setSelectedBaseCorteValue] = React.useState('neutra');

    window["get_variant"] = function () {
        return [
            selectedPoseValue,
            selectedBaseValue,
            selectedGolaValue,
            selectedMangaValue,
            selectedBaseCorteValue,
            selectedShortValue,
            $("#state-body-ra").prop("checked"),
            $("#state-socks-ra").prop("checked"),
            $("#state-shirt-ra").prop("checked"),
            $("#state-short-ra").prop("checked")
        ]
    }

    window["set_variant"] = function () {
        setSelectedPoseValue(window.variantes[0]);
        setSelectedBaseValue(window.variantes[1]);
        setSelectedGolaValue(window.variantes[2]);
        setSelectedMangaValue(window.variantes[3]);
        setSelectedBaseCorteValue(window.variantes[4]);
        setSelectedShortValue(window.variantes[5]);
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleChangePose = (event) => {
        setSelectedPoseValue(event.target.value);
        window.currentPoseSelected = event.target.value;
        updateChanges(selectedBaseValue, selectedMangaValue, selectedGolaValue, event.target.value, false);
    };

    const handleChangeModel = (event) => {
        setSelectedPoseValue(event.target.value);
        window.currentPoseSelected = event.target.value;
        updateChanges2(event.target.value);
    };

    const handleChangeBase = (event) => {
        setSelectedBaseValue(event.target.value);
        updateChanges(event.target.value, selectedMangaValue, selectedGolaValue, selectedPoseValue, true);
    };

    const handleChangeGola = (event) => {
        setSelectedGolaValue(event.target.value);
        updateChanges(selectedBaseValue, selectedMangaValue, event.target.value, selectedPoseValue, true);
    };

    const handleChangeManga = (event) => {
        setSelectedMangaValue(event.target.value);
        updateChanges(selectedBaseValue, event.target.value, selectedGolaValue, selectedPoseValue, true);
    };

    const handleChangeShort = (event) => {
        setSelectedShortValue(event.target.value);
        window.selectedShortValue = event.target.value;

        for (let i = 0; i < window.originalLayers.length; i++) {
            if (window.originalLayers[i].id == "corte_traseiro" || window.originalLayers[i].id == "corte_lateral_e" || window.originalLayers[i].id == "corte_lateral_d")
                window.originalLayers[i].visible = false;
        }


        for (var i = 0; i < window.currentPose.getObjectByName("short").children.length; i++) {
            window.currentPose.getObjectByName("short").children[i].material.alphaMap = null;
        }

        if (event.target.value == "neutra") {
            update();
            return;
        } else if (event.target.value == "barra") {
            console.log(window.alphaShort)
            for (var i = 0; i < window.currentPose.getObjectByName("short").children.length; i++) {
                window.currentPose.getObjectByName("short").children[i].material.transparent = true;
                window.currentPose.getObjectByName("short").children[i].material.alphaMap = window.alphaShort;
            }
            update();
            return;
        }

        for (let i = 0; i < window.originalLayers.length; i++) {
            if (event.target.value == "lateral") {
                if (window.originalLayers[i].id == "corte_lateral_e" || window.originalLayers[i].id == "corte_lateral_d")
                    window.originalLayers[i].visible = true;
            } else if (event.target.value == "traseiro") {
                if (window.originalLayers[i].id == "corte_traseiro")
                    window.originalLayers[i].visible = true;
            }
        }

        update();
    };

    const handleChangeBaseCorte = (event) => {
        setSelectedBaseCorteValue(event.target.value);

        for (let i = 0; i < window.originalLayers.length; i++) {
            if (window.originalLayers[i].id == "base_corte1" || window.originalLayers[i].id == "base_corte2")
                window.originalLayers[i].visible = false;
        }

        if (event.target.value == "neutra") {
            update();
            return;
        }

        for (let i = 0; i < window.originalLayers.length; i++) {
            if (window.originalLayers[i].id == "base_corte1" || window.originalLayers[i].id == "base_corte2")
                window.originalLayers[i].visible = true;
        }

        update();
    };

    function update() {
        window.fabricCanvas.requestRenderAll();
        setTimeout(() => {
            window["updateCanvas"]();
        }, 100);
    }

    function updateChanges(base, manga, gola, pose, variante) {

        $(".defaultPoses").css("display", "block");

        const path = base + "_" + manga + "_" + gola;

        window["loadParts"](
            "./models/pose" + pose + "/others.glb",
            "./models/pose" + pose + "/" + path + ".glb",
            variante,
            pose
        );
    }

    function updateChanges2(value) {

        $(".defaultPoses").css("display", "none");

        for (var i = window.currentPose.children.length - 1; i >= 0; i--) {
            window.currentPose.remove(window.currentPose.children[i]);
        }

        window["loadParts"](
            null,
            "./models/new/" + value + ".glb",
            true,
            1,
            false,
            true
        );
    }

    return (
        <>
            <p className="title lang" data-en="CHANGE CLOTH" data-es="CAMBIARSE DE ROPA" data-pt="MUDAR ROUPA">{__('CHANGE CLOTH')}</p>
            <div>
                <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
                    <AccordionSummary aria-controls="panel0d-content" id="panel0d-header">
                        <Typography>Roupas</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='list'>
                            <div className='roupa' style={{ backgroundImage: "url('/models/new/0.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '0'}
                                    onChange={handleChangePose}
                                    value="0"
                                    name="radio-buttons"
                                />
                                <label>Padrão Neutra</label>
                            </div>
                            <div className='roupa' style={{ backgroundImage: "url('/models/new/1.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '1'}
                                    onChange={handleChangePose}
                                    value="1"
                                    name="radio-buttons"
                                />
                                <label>Padrão Pose 1</label>
                            </div>
                            <div className='roupa' style={{ backgroundImage: "url('/models/new/2.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '2'}
                                    onChange={handleChangePose}
                                    value="2"
                                    name="radio-buttons"
                                />
                                <label>Padrão Pose 2</label>
                            </div>
                            <div className='roupa' style={{ backgroundImage: "url('/models/new/3.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '3'}
                                    onChange={handleChangePose}
                                    value="3"
                                    name="radio-buttons"
                                />
                                <label>Padrão Pose 3</label>
                            </div>
                            <div className='roupa' style={{ backgroundImage: "url('/models/new/4.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '4'}
                                    onChange={handleChangeModel}
                                    value="4"
                                    name="radio-buttons"
                                />
                                <label>Modelo 1</label>
                            </div>
                            <div className='roupa' style={{ backgroundImage: "url('/models/new/5.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '5'}
                                    onChange={handleChangeModel}
                                    value="5"
                                    name="radio-buttons"
                                />
                                <label>Modelo 2</label>
                            </div>
                            <div className='roupa' style={{ backgroundImage: "url('/models/new/6.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '6'}
                                    onChange={handleChangeModel}
                                    value="6"
                                    name="radio-buttons"
                                />
                                <label>Modelo 3</label>
                            </div>
                            <div className='roupa' style={{ backgroundImage: "url('/models/new/7.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '7'}
                                    onChange={handleChangeModel}
                                    value="7"
                                    name="radio-buttons"
                                />
                                <label>Modelo 4</label>
                            </div>
                            <div className='roupa' style={{ backgroundImage: "url('/models/new/8.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '8'}
                                    onChange={handleChangeModel}
                                    value="8"
                                    name="radio-buttons"
                                />
                                <label>Modelo 5</label>
                            </div>
                            <div className='roupa' style={{ backgroundImage: "url('/models/new/9.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '9'}
                                    onChange={handleChangeModel}
                                    value="9"
                                    name="radio-buttons"
                                />
                                <label>Modelo 6</label>
                            </div>
                            <div className='roupa' style={{ backgroundImage: "url('/models/new/10.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '10'}
                                    onChange={handleChangeModel}
                                    value="10"
                                    name="radio-buttons"
                                />
                                <label>Modelo 7</label>
                            </div>
                            <div className='roupa' style={{ backgroundImage: "url('/models/new/11.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '11'}
                                    onChange={handleChangeModel}
                                    value="11"
                                    name="radio-buttons"
                                />
                                <label>Modelo 8</label>
                            </div>
                            <div className='roupa' style={{ backgroundImage: "url('/models/new/12.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '12'}
                                    onChange={handleChangeModel}
                                    value="12"
                                    name="radio-buttons"
                                />
                                <label>Modelo 9</label>
                            </div>
                            <div className='roupa' style={{ backgroundImage: "url('/models/new/13.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '13'}
                                    onChange={handleChangeModel}
                                    value="13"
                                    name="radio-buttons"
                                />
                                <label>Modelo 10</label>
                            </div>
                            <div className='roupa' style={{ backgroundImage: "url('/models/new/14.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '14'}
                                    onChange={handleChangeModel}
                                    value="14"
                                    name="radio-buttons"
                                />
                                <label>Modelo 11</label>
                            </div>

                            <div className='roupa' style={{ backgroundImage: "url('/models/new/15.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '15'}
                                    onChange={handleChangeModel}
                                    value="15"
                                    name="radio-buttons"
                                />
                                <label>Modelo 12</label>
                            </div>
                            {/**<div className='roupa' style={{ backgroundImage: "url('/models/new/16.webp')" }}>
                                <Radio
                                    checked={selectedPoseValue === '16'}
                                    onChange={handleChangeModel}
                                    value="16"
                                    name="radio-buttons"
                                />
                                <label>Modelo 13</label>
                            </div> */}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="defaultPoses" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>Base</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Radio
                                checked={selectedBaseValue === 'tradicional'}
                                onChange={handleChangeBase}
                                value="tradicional"
                                name="radio-buttons"
                            />
                            <label>Tradicional</label>
                            <Radio
                                checked={selectedBaseValue === 'raglan'}
                                onChange={handleChangeBase}
                                value="raglan"
                                name="radio-buttons"
                            />
                            <label>Raglan</label>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="defaultPoses" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Gola</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='list'>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Arsenal'}
                                    onChange={handleChangeGola}
                                    value="Arsenal"
                                    name="radio-buttons"
                                />
                                <label>Arsenal</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Atletica'}
                                    onChange={handleChangeGola}
                                    value="Atletica"
                                    name="radio-buttons"
                                />
                                <label>Atlética</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Avante'}
                                    onChange={handleChangeGola}
                                    value="Avante"
                                    name="radio-buttons"
                                />
                                <label>Avante</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'CBF'}
                                    onChange={handleChangeGola}
                                    value="CBF"
                                    name="radio-buttons"
                                />
                                <label>CBF</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'EVO'}
                                    onChange={handleChangeGola}
                                    value="EVO"
                                    name="radio-buttons"
                                />
                                <label>EVO</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Gola_alta_c_ziper'}
                                    onChange={handleChangeGola}
                                    value="Gola_alta_c_ziper"
                                    name="radio-buttons"
                                />
                                <label>Alta com Ziper</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Gola_redonda'}
                                    onChange={handleChangeGola}
                                    value="Gola_redonda"
                                    name="radio-buttons"
                                />
                                <label>Redonda</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'JEC'}
                                    onChange={handleChangeGola}
                                    value="JEC"
                                    name="radio-buttons"
                                />
                                <label>JEC</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Padre'}
                                    onChange={handleChangeGola}
                                    value="Padre"
                                    name="radio-buttons"
                                />
                                <label>Padre</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Penalty'}
                                    onChange={handleChangeGola}
                                    value="Penalty"
                                    name="radio-buttons"
                                />
                                <label>Penalty</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Polo'}
                                    onChange={handleChangeGola}
                                    value="Polo"
                                    name="radio-buttons"
                                />
                                <label>Polo</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Polo_Corinthians'}
                                    onChange={handleChangeGola}
                                    value="Polo_Corinthians"
                                    name="radio-buttons"
                                />
                                <label>Polo Corinthians</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Polo_v'}
                                    onChange={handleChangeGola}
                                    value="Polo_v"
                                    name="radio-buttons"
                                />
                                <label>Polo V</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Polo_v_especial'}
                                    onChange={handleChangeGola}
                                    value="Polo_v_especial"
                                    name="radio-buttons"
                                />
                                <label>Polo V Especial</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Red_Horse'}
                                    onChange={handleChangeGola}
                                    value="Red_Horse"
                                    name="radio-buttons"
                                />
                                <label>Red Horse</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'Santos'}
                                    onChange={handleChangeGola}
                                    value="Santos"
                                    name="radio-buttons"
                                />
                                <label>Santos</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'selecao'}
                                    onChange={handleChangeGola}
                                    value="selecao"
                                    name="radio-buttons"
                                />
                                <label>Seleção</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'selecao_ribana_c_ziper'}
                                    onChange={handleChangeGola}
                                    value="selecao_ribana_c_ziper"
                                    name="radio-buttons"
                                />
                                <label>Seleção Ribana com Ziper</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'transpassada'}
                                    onChange={handleChangeGola}
                                    value="transpassada"
                                    name="radio-buttons"
                                />
                                <label>Transpassada</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedGolaValue === 'v_umbro'}
                                    onChange={handleChangeGola}
                                    value="v_umbro"
                                    name="radio-buttons"
                                />
                                <label>V Umbro</label>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="defaultPoses" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography>Manga</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Radio
                                checked={selectedMangaValue === 'curta'}
                                onChange={handleChangeManga}
                                value="curta"
                                name="radio-buttons"
                            />
                            <label>Curta</label>
                            <Radio
                                checked={selectedMangaValue === '3_4'}
                                onChange={handleChangeManga}
                                value="3_4"
                                name="radio-buttons"
                            />
                            <label>3/4</label>
                            <Radio
                                checked={selectedMangaValue === 'longa'}
                                onChange={handleChangeManga}
                                value="longa"
                                name="radio-buttons"
                            />
                            <label>Longa</label>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="defaultPoses" expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                        <Typography>Base Cortes</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Radio
                                checked={selectedBaseCorteValue === 'neutra'}
                                onChange={handleChangeBaseCorte}
                                value="neutra"
                                name="radio-buttons"
                            />
                            <label>Base sem corte lateral</label>
                            <Radio
                                checked={selectedBaseCorteValue === 'corte'}
                                onChange={handleChangeBaseCorte}
                                value="corte"
                                name="radio-buttons"
                            />
                            <label>Base corte lateral</label>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="defaultPoses" expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                        <Typography>Short</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='list'>
                            <div>
                                <Radio
                                    checked={selectedShortValue === 'neutra'}
                                    onChange={handleChangeShort}
                                    value="neutra"
                                    name="radio-buttons"
                                />
                                <label>2 partes inteiro</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedShortValue === 'lateral'}
                                    onChange={handleChangeShort}
                                    value="lateral"
                                    name="radio-buttons"
                                />
                                <label>Corte lateral</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedShortValue === 'barra'}
                                    onChange={handleChangeShort}
                                    value="barra"
                                    name="radio-buttons"
                                />
                                <label>Corte Barra</label>
                            </div>
                            <div>
                                <Radio
                                    checked={selectedShortValue === 'traseiro'}
                                    onChange={handleChangeShort}
                                    value="traseiro"
                                    name="radio-buttons"
                                />
                                <label>Corte trazeiro</label>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export default ChangeSport